import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useLocation, useNavigate } from "react-router-dom";
import { SeevClient } from "@/lib/SeevClient";
import { FormattedMessage, useIntl } from "react-intl";
import { useState } from "react";
import { Card, CardTitle } from "@/components/ui/card";
import { createPasswordSchema } from "@/lib/validation/passwordPolicy";

export function ResetPassword() {
  const intl = useIntl();

  const formSchema = createPasswordSchema(intl, { confirmPassword: true });

  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [resetSuccessful, setResetSuccessful] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    setLoading(true);

    const from = location.pathname + location.search || "";
    const password = values.password || "";
    const confirmPassword = values.confirmPassword || "";

    if (password === confirmPassword) {
      try {
        await SeevClient.authManager.resetPassword(from, password);
        setResetSuccessful(true);
        navigate("/login");
      } catch (error) {
        form.setError("confirmPassword", {
          type: "manual",
          message: intl.formatMessage({
            id: "login.resetPasswordUnSuccessful",
          }),
        });
      }
    } else {
      form.setError("confirmPassword", {
        type: "manual",
        message: intl.formatMessage({ id: "login.validation.match" }),
      });
    }

    setLoading(false);
  };

  return (
    <div className="flex flex-row">
      <div className="flex w-full items-center justify-center h-screen">
        <Card className="flex flex-col p-2 w-[400px]">
          <div className="p-8">
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="flex flex-col gap-6"
              >
                <CardTitle>
                  <FormattedMessage id="login.resetPassword" />
                </CardTitle>
                <div className="flex pt-4 pb-4">
                  <FormattedMessage id="login.resetPasswordDescription" />
                </div>
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          type="password"
                          placeholder={intl.formatMessage({
                            id: "login.newPassword",
                          })}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="confirmPassword"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          type="password"
                          placeholder={intl.formatMessage({
                            id: "login.newPasswordConfirmation",
                          })}
                          {...field}
                          value={field.value as string}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Button type="submit" disabled={loading || resetSuccessful}>
                  {resetSuccessful ? (
                    <FormattedMessage id="login.resetPasswordSuccessful" />
                  ) : (
                    <FormattedMessage id="login.reset" />
                  )}
                </Button>
              </form>
            </Form>
          </div>
          <hr />
          <Button
            variant={"link"}
            className="p-2"
            onClick={() => {
              navigate("/login");
            }}
          >
            <FormattedMessage id="login.remember" />
          </Button>
        </Card>
      </div>
    </div>
  );
}

export default ResetPassword;
