import { useForm } from "react-hook-form";
import { z } from "zod";
import * as Sentry from "@sentry/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { cn } from "@/lib/utils";

import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { useIntl, FormattedMessage } from "react-intl";
import { Card } from "@/components/ui/card";
import { SeevClient } from "@/lib/SeevClient/SeevClient";
import { toast } from "@/hooks/ToastHook/use-toast";
import { Toaster } from "@/components/Toaster/Toaster";
import { createPasswordSchema } from "@/lib/validation/passwordPolicy";

export function SignUp() {
  const intl = useIntl();

  const passwordValidation = createPasswordSchema(intl, {
    confirmPassword: true,
  });

  const signupSchema = z
    .object({
      firstName: z.string().min(1, {
        message: intl.formatMessage({
          id: "signup.validation.firstNameRequired",
        }),
      }),
      lastName: z.string().min(1, {
        message: intl.formatMessage({
          id: "signup.validation.lastNameRequired",
        }),
      }),
      companyName: z.string().min(1, {
        message: intl.formatMessage({
          id: "signup.validation.companyNameRequired",
        }),
      }),
      workEmail: z
        .string()
        .min(1, {
          message: intl.formatMessage({
            id: "signup.validation.emailRequired",
          }),
        })
        .email({
          message: intl.formatMessage({ id: "signup.validation.invalidEmail" }),
        }),
      terms: z.boolean().refine((val) => val === true, {
        message: intl.formatMessage({ id: "signup.validation.termsRequired" }),
      }),
    })
    .and(passwordValidation);

  type SignupSchemaType = z.infer<typeof signupSchema>;

  const form = useForm<SignupSchemaType>({
    resolver: zodResolver(signupSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      companyName: "",
      workEmail: "",
      password: "",
      confirmPassword: "",
      terms: false,
    },
    mode: "onBlur",
    reValidateMode: "onChange", // Revalidate while typing
  });

  const onSubmit = async (data: SignupSchemaType) => {
    try {
      const response_status = await SeevClient.authManager.signUp({
        firstName: data.firstName,
        lastName: data.lastName,
        organizationName: data.companyName,
        email: data.workEmail,
        password: data.password,
      });

      if (response_status === 201) {
        toast({
          title: intl.formatMessage({ id: "signup.successTitle" }),
          description: intl.formatMessage({
            id: "signup.successDescription",
          }),
          variant: "success",
        });
        await SeevClient.authManager.login(data.workEmail, data.password);
      } else {
        form.setError("root", {
          type: "manual",
          message: intl.formatMessage({ id: "signup.generalError" }),
        });
      }
    } catch (error) {
      Sentry.captureException(error);
      if (error instanceof Error && error.message === "user_already_exists") {
        toast({
          title: intl.formatMessage({ id: "signup.userAlreadyExistsTitle" }),
          description: intl.formatMessage({
            id: "signup.userAlreadyExistsDescription",
          }),
          variant: "warning",
        });
        form.setError("workEmail", {
          type: "manual",
          message: intl.formatMessage({ id: "signup.userAlreadyExistsTitle" }),
        });
      } else {
        form.setError("root", {
          type: "manual",
          message: intl.formatMessage({ id: "signup.generalError" }),
        });
      }
    }
  };

  return (
    <div className="w-full max-w-md">
      <Card className="p-8 bg-white/80 dark:bg-gray-800/80 rounded-lg shadow-lg">
        <div className="mb-6">
          <h1 className="text-3xl font-bold text-center text-gray-800 dark:text-gray-100">
            <FormattedMessage id="signup.title" />
          </h1>
          <p className="mt-2 text-xl text-center text-indigo-600 dark:text-indigo-400">
            <FormattedMessage id="signup.catchphrase" />
          </p>
        </div>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
            {/* First Name */}
            <FormField
              control={form.control}
              name="firstName"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      {...field}
                      placeholder={intl.formatMessage({
                        id: "signup.firstName",
                      })}
                      className={cn(
                        "bg-white/50 dark:bg-gray-700/50 backdrop-blur-sm",
                        fieldState.error && "border-red-500",
                      )}
                    />
                  </FormControl>
                  <FormMessage>
                    {fieldState.error &&
                      intl.formatMessage({
                        id:
                          fieldState.error.message ||
                          "signup.validation.generalError",
                      })}
                  </FormMessage>
                </FormItem>
              )}
            />

            {/* Last Name */}
            <FormField
              control={form.control}
              name="lastName"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      {...field}
                      placeholder={intl.formatMessage({
                        id: "signup.lastName",
                      })}
                      className={cn(
                        "bg-white/50 dark:bg-gray-700/50 backdrop-blur-sm",
                        fieldState.error && "border-red-500",
                      )}
                    />
                  </FormControl>
                  <FormMessage>
                    {fieldState.error &&
                      intl.formatMessage({
                        id:
                          fieldState.error.message ||
                          "signup.validation.generalError",
                      })}
                  </FormMessage>
                </FormItem>
              )}
            />

            {/* Work Email */}
            <FormField
              control={form.control}
              name="workEmail"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      {...field}
                      type="email"
                      placeholder={intl.formatMessage({
                        id: "signup.workEmail",
                      })}
                      className={cn(
                        "bg-white/50 dark:bg-gray-700/50 backdrop-blur-sm",
                        fieldState.error && "border-red-500",
                      )}
                    />
                  </FormControl>
                  <FormMessage>
                    {fieldState.error &&
                      intl.formatMessage({
                        id:
                          fieldState.error.message ||
                          "signup.validation.generalError",
                      })}
                  </FormMessage>
                </FormItem>
              )}
            />

            {/* Company Name */}
            <FormField
              control={form.control}
              name="companyName"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      {...field}
                      placeholder={intl.formatMessage({
                        id: "signup.companyName",
                      })}
                      className={cn(
                        "bg-white/50 dark:bg-gray-700/50 backdrop-blur-sm",
                        fieldState.error && "border-red-500",
                      )}
                    />
                  </FormControl>
                  <FormMessage>
                    {fieldState.error &&
                      intl.formatMessage({
                        id:
                          fieldState.error.message ||
                          "signup.validation.generalError",
                      })}
                  </FormMessage>
                </FormItem>
              )}
            />

            {/* Password */}
            <FormField
              control={form.control}
              name="password"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      type="password"
                      {...field}
                      placeholder={intl.formatMessage({
                        id: "signup.password",
                      })}
                      className={cn(
                        "bg-white/50 dark:bg-gray-700/50 backdrop-blur-sm",
                        fieldState.error && "border-red-500",
                      )}
                    />
                  </FormControl>
                  <FormMessage>
                    {fieldState.error &&
                      intl.formatMessage({
                        id:
                          fieldState.error.message ||
                          "signup.validation.generalError",
                      })}
                  </FormMessage>
                </FormItem>
              )}
            />

            {/* Confirm Password */}
            <FormField
              control={form.control}
              name="confirmPassword"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      type="password"
                      {...field}
                      value={field.value as string}
                      placeholder={intl.formatMessage({
                        id: "signup.confirmPassword",
                      })}
                      className={cn(
                        "bg-white/50 dark:bg-gray-700/50 backdrop-blur-sm",
                        fieldState.error && "border-red-500",
                      )}
                    />
                  </FormControl>
                  <FormMessage>
                    {fieldState.error &&
                      intl.formatMessage({
                        id:
                          fieldState.error.message ||
                          "signup.validation.generalError",
                      })}
                  </FormMessage>
                </FormItem>
              )}
            />

            {/* Terms and conditions & Privacy policy Check */}
            <FormField
              control={form.control}
              name="terms"
              render={({ field, fieldState }) => (
                <FormItem className="flex items-center space-x-2">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={(checked) => field.onChange(checked)}
                    />
                  </FormControl>
                  <div className="flex items-center space-x-1">
                    <span className="text-sm text-gray-700 dark:text-gray-300">
                      <FormattedMessage
                        id="signup.terms.agreement"
                        values={{
                          terms: (
                            <a
                              href="https://seev.ai/conditions-de-service/"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-indigo-600 dark:text-indigo-400 hover:underline"
                            >
                              <FormattedMessage id="signup.terms.termsAndConditions" />
                            </a>
                          ),
                          privacy: (
                            <a
                              href="https://seev.ai/politique-de-confidentialite/"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-indigo-600 dark:text-indigo-400 hover:underline"
                            >
                              <FormattedMessage id="signup.terms.privacyPolicy" />
                            </a>
                          ),
                        }}
                      />
                    </span>
                  </div>
                  <FormMessage>
                    {fieldState.error &&
                      intl.formatMessage({
                        id:
                          fieldState.error.message ||
                          "signup.validation.generalError",
                      })}
                  </FormMessage>
                </FormItem>
              )}
            />

            {/* Submit Button */}
            <Button
              type="submit"
              className="w-full bg-indigo-600 hover:bg-indigo-700 text-white dark:bg-indigo-500 dark:hover:bg-indigo-600"
            >
              <FormattedMessage id="global.signUp" />
            </Button>

            {/* Show form-level errors if any */}
            {form.formState.errors.root && (
              <div className="text-red-500 text-sm">
                {form.formState.errors.root.message}
              </div>
            )}
          </form>
        </Form>
        <div className="mt-4 text-center">
          <a
            href="/login"
            className="text-sm text-indigo-600 dark:text-indigo-400 hover:underline"
          >
            <FormattedMessage id="signup.alreadySignedUp" />
          </a>
        </div>
      </Card>
      <Toaster />
    </div>
  );
}

export default SignUp;
