import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { FormattedMessage } from "react-intl";
import OutlineTable from "./components/OutlineTable";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useState } from "react";
import { OutlineSection } from "@/types/entities/Outline";
import { SeevClient } from "@/lib/SeevClient";
import { Project } from "@/types/entities/Project";
import RequiredCompanyDataCard from "./components/RequiredCompanyData";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import PlaceholderCard from "./components/PlaceholderCard";

export interface OutlineCardProps {
  project: Project;
  setProject: (project: Project) => void;
  onSectionClick: (section: OutlineSection) => void;
}

function OutlineCard({
  project,
  setProject,
  onSectionClick,
}: OutlineCardProps) {
  const [sectionList, setSectionList] = useState<OutlineSection[]>(
    project?.outline?.sections || [],
  );

  const updateSections = async (updatedSections: OutlineSection[]) => {
    try {
      setSectionList(updatedSections);
      await SeevClient.project.updateProject(project.id, {
        outline: { sections: updatedSections },
      });
      setProject({
        ...project,
        outline: {
          ...project.outline,
          sections: updatedSections,
        },
      });
    } catch (error) {
      console.error("Failed to update sections", error);
    }
  };

  const handleUpdate = (updatedSection: OutlineSection) => {
    updateSections(
      sectionList.map((sec) =>
        sec.id === updatedSection.id ? updatedSection : sec,
      ),
    );
  };

  const handleDelete = (sectionId: string) => {
    updateSections(sectionList.filter((sec) => sec.id !== sectionId));
  };

  return (
    <Tabs defaultValue="outline" className="w-full flex-1 h-[650px]">
      <Card className="h-[650px]">
        <CardHeader>
          <CardTitle style={{ paddingRight: "10px" }}>
            <FormattedMessage id="project.outline.title" />
          </CardTitle>
          <TabsList>
            <TabsTrigger value="outline">
              <FormattedMessage id="project.outline.tabs.outline.title" />
            </TabsTrigger>
            <TabsTrigger value="content">
              <FormattedMessage id="project.outline.tabs.content.title" />
            </TabsTrigger>
          </TabsList>
        </CardHeader>
        <ScrollArea className="h-[550px]">
          <TabsContent value="outline">
            <OutlineTable
              outline={{ sections: sectionList }}
              globalFilter={""}
              onRowClick={onSectionClick}
              onChange={handleUpdate}
              onDelete={handleDelete}
            />
          </TabsContent>
          <TabsContent value="content">
            <PlaceholderCard project={project} setProject={setProject} />
            <RequiredCompanyDataCard
              project={project}
              setProject={setProject}
            />
          </TabsContent>
        </ScrollArea>
      </Card>
    </Tabs>
  );
}

export default OutlineCard;
