import { z } from "zod";
import { IntlShape } from "react-intl";

export function createPasswordSchema(
  intl: IntlShape,
  options: { confirmPassword?: boolean } = {},
) {
  const passwordSchema = z
    .object({
      password: z
        .string()
        .min(8, {
          message: intl.formatMessage({
            id: "passwordPolicy.validation.length",
          }),
        })
        .max(64, {
          message: intl.formatMessage({
            id: "passwordPolicy.validation.maxLength",
          }),
        })
        .regex(/(?=.*[A-Z])/, {
          message: intl.formatMessage({
            id: "passwordPolicy.validation.uppercase",
          }),
        })
        .regex(/(?=.*[a-z])/, {
          message: intl.formatMessage({
            id: "passwordPolicy.validation.lowercase",
          }),
        })
        .regex(/(?=.*[0-9])/, {
          message: intl.formatMessage({
            id: "passwordPolicy.validation.number",
          }),
        })
        .regex(/(?=.*[!@#$%^&*])/, {
          message: intl.formatMessage({
            id: "passwordPolicy.validation.special",
          }),
        }),
      ...(options.confirmPassword
        ? {
            confirmPassword: z.string(),
          }
        : {}),
    })
    .refine(
      (data) => {
        if (options.confirmPassword) {
          return data.password === data.confirmPassword;
        }
        return true;
      },
      {
        message: intl.formatMessage({ id: "passwordPolicy.validation.match" }),
        path: ["confirmPassword"],
      },
    );

  return passwordSchema;
}
