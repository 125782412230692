import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { FormattedMessage } from "react-intl";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Project } from "@/types/entities/Project";
import { Summary } from "@/types/entities/Project";
import { GeneralInformation, SummarySection } from "./sections";
import PenaltiesSection from "./sections/PenaltiesSection/PernaltiesSection";
import FirmRequiredExperiencesSection from "./sections/FirmRequiredExperiencesSection/FirmRequiredExperiencesSection";
import ResourceRequiredExperiencesSection from "./sections/ResourceRequiredExperiencesSection/ResourceRequiredExperiencesSection";
import ScopeOfWorkSection from "./sections/ScopeOfWork/ScopeOfWork";
import ProjectStructureSection from "./sections/ProjectStructure/ProjectStructure";
import EvaluationCriteriaSection from "./sections/EvaluationCriteria/EvaluationCriteriaSection";
import CertificationsSection from "./sections/CertificationsSection/CertificationsSection";
import InconsistenciesSection from "./sections/InconsistenciesSection/InconsistenciesSection";
import TechnologyEnvironmentSection from "./sections/TechnologyEnvironmentSection/TechnologyEnvironmentSection";
import { featureFlags } from "@/config/featureFlags";
import { Forward } from "lucide-react";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import ShareDialog from "../../../Project/components/ToolBar/components/ShareDialog";
import AddFileToProjectDialog from "../../../Project/components/ToolBar/components/AddFileToProjectDialog";

interface ProjectCardProps {
  project: Project;
  loading: boolean;
  updateSummary: (updatedSummary: Summary) => Promise<void>;
}

function SummaryCard({ project, loading, updateSummary }: ProjectCardProps) {
  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  const handleAddFile = () => {
    // Implement any additional logic if needed after adding a file
    // For example, refresh the project data
    updateSummary(project.summary!);
  };

  return (
    <Card className="flex-1 h-[650px]">
      <CardHeader>
        <CardTitle className="!w-full flex items-center justify-between gap-2">
          <FormattedMessage id="project.summary" />
          <div className="flex space-x-2">
            <Button size="sm" onClick={() => setShareDialogOpen(true)}>
              <Forward className="mr-2 h-5 w-5" />
              <FormattedMessage id="global.share" />
            </Button>
            {featureFlags().enableProjectFileUpdate && (
              <AddFileToProjectDialog
                projectId={project.id}
                onAddFile={handleAddFile}
              />
            )}
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <ScrollArea className="flex-1 h-[565px]">
          {project.summary ? (
            <div>
              <SummarySection
                summary={project.summary}
                loading={loading}
                onSubmit={updateSummary}
              />
              <GeneralInformation
                summary={project.summary}
                loading={loading}
                onSubmit={updateSummary}
              />
              <ScopeOfWorkSection
                summary={project.summary}
                loading={loading}
                onSubmit={updateSummary}
              />
              <ProjectStructureSection
                summary={project.summary}
                loading={loading}
                onSubmit={updateSummary}
              />
              <TechnologyEnvironmentSection
                summary={project.summary}
                loading={loading}
                onSubmit={updateSummary}
              />
              <FirmRequiredExperiencesSection
                summary={project.summary}
                loading={loading}
                onSubmit={updateSummary}
              />
              <ResourceRequiredExperiencesSection
                summary={project.summary}
                loading={loading}
                onSubmit={updateSummary}
              />
              <CertificationsSection
                summary={project.summary}
                loading={loading}
                onSubmit={updateSummary}
              />
              <EvaluationCriteriaSection
                summary={project.summary}
                loading={loading}
                onSubmit={updateSummary}
              />
              <PenaltiesSection
                summary={project.summary}
                loading={loading}
                onSubmit={updateSummary}
              />
              {featureFlags().enableInconsistencies &&
                project.summary.itemsToValidate &&
                project.summary.itemsToValidate.length > 0 && (
                  <InconsistenciesSection
                    summary={project.summary}
                    loading={loading}
                    onSubmit={updateSummary}
                  />
                )}
            </div>
          ) : (
            <FormattedMessage id="project.summaryNotExists" />
          )}
        </ScrollArea>
      </CardContent>
      <ShareDialog
        open={shareDialogOpen}
        project={project}
        onClose={() => setShareDialogOpen(false)}
        onSave={() => setShareDialogOpen(false)}
      />
    </Card>
  );
}

export default SummaryCard;
