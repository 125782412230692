import { OutlineCard, OutlineDetailCard } from "../Cards/Outline";
import { OutlineSection } from "@/types/entities/Outline";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { useState, useEffect } from "react";
import { Project } from "@/types/entities/Project";
import { featureFlags } from "@/config/featureFlags";
import { Button } from "@/components/ui/button";
import { WandSparkles } from "lucide-react";
import { FormattedMessage } from "react-intl";
import { SeevClient } from "@/lib/SeevClient";
import { Loading } from "@/components/Loading/Loading";

interface OutlineProps {
  project: Project;
  setProject: (project: Project) => void;
}

function Proposal({ project, setProject }: OutlineProps) {
  const [selectedSection, setSelectedSection] = useState<OutlineSection | null>(
    project?.outline?.sections?.[0] || null,
  );

  const handleSectionClick = (section: OutlineSection) => {
    setSelectedSection(section);
  };

  const [generatingOutline, setGeneratingOutline] = useState(
    project.outlineStatus === "PARSING",
  );

  const handleGenerateOutline = async () => {
    setProject({ ...project, outlineStatus: "PARSING" });
    SeevClient.project.extractTablesForms(project.id);
  };

  useEffect(() => {
    if (project.outlineStatus === "PARSING") {
      setGeneratingOutline(true);
    } else {
      setGeneratingOutline(false);
    }
  }, [project.outlineStatus]);

  return (
    <div className="flex flex-wrap xl:flex-nowrap h-full gap-4">
      {generatingOutline ? (
        <div className="relative h-full w-full mt-32">
          <div className="mt-24">
            <Loading messageId="project.outline.parsing" />
          </div>
        </div>
      ) : project.outlineStatus === "NOT_PARSED" ? (
        <div className="w-full flex justify-end">
          <Button
            variant="default"
            size="sm"
            className="w-max mb-3"
            onClick={() => handleGenerateOutline()}
          >
            <WandSparkles className="mr-2 h-4 w-4" />
            <FormattedMessage id="project.outline.generate" />
          </Button>
        </div>
      ) : project.outlineStatus === "PARSE_FAILED" ? (
        <div className="mt-12 flex flex-col gap-6 text-center justify-center items-center bg-transparent opacity-70 text-4xl">
          <FormattedMessage id="project.outline.parseFailed" />
          <Button
            variant="default"
            className="w-max"
            onClick={() => handleGenerateOutline()}
          >
            <WandSparkles className="mr-2 h-4 w-4" />
            <FormattedMessage id="project.outline.regenerate" />
          </Button>
        </div>
      ) : project.outlineStatus === "PARSED" ? (
        <div className="flex flex-col w-full gap-4">
          <div className="flex w-full gap-4 xl:flex-nowrap flex-wrap">
            <div className="flex w-full xl:w-3/5 h-full flex-col gap-4">
              <OutlineCard
                project={project}
                setProject={setProject}
                onSectionClick={handleSectionClick}
              />
            </div>
            <div className="flex-1 w-full xl:w-2/5 h-full flex flex-col gap-4">
              {selectedSection &&
                (featureFlags().enableOutlineV2 ? (
                  <OutlineDetailCard section={selectedSection} />
                ) : (
                  <Card>
                    <CardHeader>
                      <CardTitle>{selectedSection.title}</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <p>{selectedSection.description}</p>
                    </CardContent>
                  </Card>
                ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-12"></div>
      )}
    </div>
  );
}

export default Proposal;
