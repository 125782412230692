import { Card, CardHeader, CardContent, CardTitle } from "@/components/ui/card";
import { FormattedMessage } from "react-intl";
import {
  Paragraph,
  TableOrForm,
  Placeholder,
  OutlineSection,
  DirectContent,
} from "@/types/entities/Outline";
import { ScrollArea } from "@/components/ui/scroll-area";

export interface OutlineDetailCardProps {
  section: OutlineSection;
}

function ParagraphContent(paragraph: Paragraph) {
  return (
    <p className="text-xs px-4 py-2 !border rounded-lg">
      {paragraph.description}
    </p>
  );
}
function TableOrFormContent(tableOrForm: TableOrForm) {
  return (
    <p className="text-xs px-4 py-2 !border rounded-lg">
      {tableOrForm.name +
        (tableOrForm.differentiation
          ? ` (${tableOrForm.differentiation})`
          : "")}
    </p>
  );
}
function PlaceholderContent(placeholder: Placeholder) {
  return (
    <p className="text-xs px-4 py-2 !border rounded-lg">
      {`${placeholder.name} ${
        placeholder.differentiation ? `(${placeholder.differentiation})` : ""
      }`}
    </p>
  );
}

// function FakeStuff() {
//   return (
//     <>
//       {/* Relevant Documents & Input Needed */}
//       <div className="text-sm grid xl:grid-cols-3 gap-x-10 gap-y-6">
//         {/* Relevant Documents */}
//         <div className="xl:col-span-2 w-full flex flex-col mt-6 gap-1.5">
//           <div className="flex items-center justify-between">
//             <span className="font-medium">
//               <FormattedMessage id="project.outline.detail.RelevantDocuments" />
//             </span>
//             <Button variant="outline" size="xs">
//               <Plus className="mr-2 h-4 w-4" />
//               <FormattedMessage id="global.add" />
//             </Button>
//           </div>
//           <div className="flex flex-col divide-y gap-y-2 text-sm w-full px-5 pb-3 !border leading-6 rounded-lg">
//             {["Standard_Hourly_Rates.xlsx", "Staff_roles.xlsx"].map((req) => (
//               <div className="flex items-center justify-between pt-3" key={req}>
//                 <span>{req}</span>
//                 <Eye className="text-gray-500 h-5 w-5 cursor-pointer" />
//               </div>
//             ))}
//           </div>
//         </div>
//         {/* Input Needed */}
//         <div className="w-full flex flex-col mt-6 gap-1.5">
//           <span className="font-medium">
//             <FormattedMessage id="project.outline.detail.InputNeeded" />
//           </span>
//           <div className="flex flex-col divide-y gap-y-2 text-sm w-full px-5 pb-3 !border leading-6 rounded-lg">
//             {["Estimate hours"].map((req) => (
//               <div className="flex items-center justify-between pt-3" key={req}>
//                 <span>{req}</span>
//                 <CircleCheckBig className="text-gray-500 h-5 w-5 cursor-pointer" />
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>

//       {/* Requirements Supported */}
//       <div className="text-sm w-max flex flex-col mt-6 gap-1.5">
//         <span className="font-medium">
//           <FormattedMessage id="project.outline.detail.RequirementsSupported" />
//         </span>
//         <div className="flex flex-col divide-y gap-y-2 text-sm w-full px-5 pb-3 !border leading-6 rounded-lg">
//           <div className="flex !flex-col 2xl:!flex-row 2xl:!items-center gap-1.5 pt-3">
//             <span> The Pricing is to be provided in the format... </span>
//             <span className="text-primary hover:text-pastel-blue cursor-pointer pr-4">
//               {" "}
//               See in Compliance Matrix{" "}
//             </span>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

function OutlineDetailCard({ section }: OutlineDetailCardProps) {
  return (
    <Card className="h-[650px]">
      <ScrollArea className="h-[640px]">
        <CardHeader>
          <CardTitle>
            <FormattedMessage id="project.outline.detail.title" />
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col gap-2">
            <h1>
              {section.number} {section.title}
            </h1>

            {/* Write-Up */}
            <div className="text-sm flex flex-col mt-4 gap-1.5">
              <span className="font-semibold">
                <FormattedMessage id="project.outline.detail.WriteUpPlan" />
              </span>
              <p className="text-xs w-full px-4 py-2 !border leading-6 rounded-lg">
                {section.description}
              </p>
            </div>

            {/* Direct Contents */}
            <div className="py-2.5 flex flex-col gap-4">
              {section.directContents.map((content: DirectContent) => (
                <div
                  key={content.id}
                  className="text-sm flex flex-col gap-1.5 pl-6"
                >
                  <div className="flex justify-between items-center">
                    <span className="text-muted-foreground">
                      <FormattedMessage
                        id={`project.outline.detail.type.${content.type}`}
                      />
                    </span>
                  </div>
                  {content.type === "paragraph" &&
                    ParagraphContent(content as Paragraph)}
                  {content.type === "table" &&
                    TableOrFormContent(content as TableOrForm)}
                  {content.type === "form" &&
                    TableOrFormContent(content as TableOrForm)}
                  {content.type === "placeholder" &&
                    PlaceholderContent(content as Placeholder)}
                </div>
              ))}
            </div>
            {/* <FakeStuff /> */}
          </div>
        </CardContent>
      </ScrollArea>
    </Card>
  );
}

export default OutlineDetailCard;
