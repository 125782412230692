import * as React from "react";
import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  Row,
  Table as TableType,
  CellContext,
} from "@tanstack/react-table";
import { ArrowUpDown, SquarePen, Trash2 } from "lucide-react";
import { FormattedMessage } from "react-intl";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Input } from "@/components/ui/input";
import { Cross2Icon, CheckIcon } from "@radix-ui/react-icons";
import { Outline, OutlineSection } from "@/types/entities/Outline";

interface EditableCellProps {
  colId: string;
  cell: CellContext<OutlineSection, unknown>;
  onChange: (value: string) => void;
}

const EditableCell = (props: EditableCellProps) => {
  const { cell, onChange, colId } = props;
  const [edit, setEdit] = React.useState<string>();

  return cell.row.getIsSelected() ? (
    <Input
      defaultValue={cell.row.getValue(colId)}
      value={edit}
      className="!max-h-9"
      onChange={(event) => {
        onChange(event.target.value);
        setEdit(event.target.value);
      }}
    />
  ) : colId === "number" ? (
    <div
      style={{
        paddingLeft: `${
          ((cell.row.getValue(colId) as string).match(/\./g) || []).length * 10
        }px`,
      }}
    >
      {cell.row.getValue(colId)}
    </div>
  ) : (
    <div>{cell.row.getValue(colId)}</div>
  );
};

export interface OutlineTableProps {
  outline: Outline;
  globalFilter: string;
  onRowClick: (section: OutlineSection) => void;
  onChange: (section: OutlineSection) => void;
  onDelete: (sectionId: string) => void;
}

export const OutlineTable = ({
  outline,
  globalFilter,
  onRowClick,
  onChange,
  onDelete,
}: OutlineTableProps) => {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [editSectionId, setEditSectionId] = React.useState<string | null>(null);
  const editRef = React.useRef<OutlineSection | null>(null);
  const tableRef = React.useRef<TableType<OutlineSection> | null>(null);

  const unselectAll = React.useCallback(() => {
    if (!tableRef.current) return;

    tableRef.current
      .getRowModel()
      .rows.forEach((row) => row.toggleSelected(false));
  }, [tableRef]);

  const selectRow = React.useCallback(
    (row: Row<OutlineSection>, sectionId: string) => {
      editRef.current = row.original;
      handleEdit(sectionId);
      if (!row.getIsSelected()) {
        unselectAll();
        row.toggleSelected(true);
      }
    },
    [unselectAll],
  );

  const handleEdit = (sectionId: string) => {
    setEditSectionId(sectionId);
  };

  const handleChanges = React.useCallback(() => {
    if (!editRef.current || editSectionId === null) return;

    unselectAll();
    onChange(editRef.current);
  }, [onChange, unselectAll, editSectionId]);

  const handleCancel = React.useCallback(() => {
    unselectAll();
  }, [unselectAll]);

  const columns: ColumnDef<OutlineSection>[] = React.useMemo(
    () => [
      {
        accessorKey: "number",
        header: ({ column }) => {
          return (
            <Button
              variant="ghost"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }
            >
              <FormattedMessage id="project.outline.table.fields.number" />
              <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
          );
        },
        cell: (cell) => (
          <EditableCell
            colId="number"
            cell={cell}
            onChange={(value) => {
              editRef.current = editRef.current && {
                ...editRef.current,
                number: value,
              };
            }}
          />
        ),
      },
      {
        accessorKey: "title",
        header: ({ column }) => {
          return (
            <Button
              variant="ghost"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }
            >
              <FormattedMessage id="project.outline.table.fields.title" />
              <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
          );
        },
        cell: (cell) => (
          <EditableCell
            colId="title"
            cell={cell}
            onChange={(value) => {
              editRef.current = editRef.current && {
                ...editRef.current,
                title: value,
              };
            }}
          />
        ),
      },
      {
        accessorKey: "actions",
        header: () => {
          return (
            <div className="text-center">
              <FormattedMessage id="opportunities.fields.action" />
            </div>
          );
        },
        cell: ({ row }) => {
          return (
            <div>
              {row.getIsSelected() ? (
                <div className="flex justify-center items-center gap-1">
                  <Button
                    variant="ghost"
                    size="icon-sm"
                    onClick={() => handleChanges()}
                  >
                    <CheckIcon />
                  </Button>
                  <Button
                    variant="ghost"
                    size="icon-sm"
                    onClick={() => handleCancel()}
                  >
                    <Cross2Icon />
                  </Button>
                </div>
              ) : (
                <div className="flex justify-center items-center gap-1">
                  <Button
                    variant="ghost"
                    size="icon-sm"
                    onClick={() => selectRow(row, row.original.id)}
                  >
                    <SquarePen className="!text-muted-foreground !h-4 !w-4" />
                  </Button>
                  <Button
                    variant="ghost"
                    size="icon-sm"
                    onClick={() => onDelete(row.original.id)}
                  >
                    <Trash2 className="!text-muted-foreground !h-4 !w-4" />
                  </Button>
                </div>
              )}
            </div>
          );
        },
      },
    ],
    [handleChanges, handleCancel, selectRow, onDelete],
  );

  const table = useReactTable({
    data: outline.sections,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      globalFilter,
    },
  });

  tableRef.current = table;

  return (
    <Table>
      <TableHeader>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <TableHead key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </TableHead>
              );
            })}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {table.getRowModel().rows?.length ? (
          table.getRowModel().rows.map((row) => (
            <TableRow
              key={row.id}
              onClick={() => onRowClick(row.original)}
              onDoubleClick={() => selectRow(row, row.original.id)}
            >
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={columns.length} className="h-24 text-center">
              No data available
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default OutlineTable;
