import { Project } from "@/types/entities/Project";
import { Button } from "@/components/ui/button";
import { SeevClient } from "@/lib/SeevClient";
import { useState } from "react";
import { Upload, X, File } from "lucide-react";
import { useDropzone } from "react-dropzone";
import { RequiredCompanyDataDocument } from "@/types/entities/Project";

interface RequiredCompanyDataCardProps {
  project: Project;
  setProject: (project: Project) => void;
}

interface UploadState {
  [key: string]: {
    documents: RequiredCompanyDataDocument[];
    uploading: boolean;
  };
}

function RequiredCompanyDataCard({
  project,
  setProject,
}: RequiredCompanyDataCardProps) {
  const [uploadState, setUploadState] = useState<UploadState>({});

  const handleFileUpload = async (files: File[], dataId: string) => {
    setUploadState((prev) => ({
      ...prev,
      [dataId]: {
        documents: [],
        uploading: true,
      },
    }));

    try {
      const uploadPromises = files.map(async (file) => {
        const response = await SeevClient.file.uploadNewFile(file, true);
        return {
          id: response?.id || "",
          name: response?.name || "",
        };
      });

      const documents = await Promise.all(uploadPromises);

      const currentDocuments =
        project.requiredCompanyData?.find((d) => d.id === dataId)?.documents ||
        [];
      const newDocuments = [...currentDocuments, ...documents];

      const newRequiredCompanyData = project.requiredCompanyData?.map((data) =>
        data.id === dataId ? { ...data, documents: newDocuments } : data,
      );

      await SeevClient.project.updateProject(project.id, {
        requiredCompanyData: newRequiredCompanyData,
      });

      setProject({
        ...project,
        requiredCompanyData: newRequiredCompanyData,
      });

      // Clear the upload state for this dataId
      setUploadState((prev) => {
        const newState = { ...prev };
        delete newState[dataId];
        return newState;
      });
    } catch (error) {
      console.error("Upload failed:", error);
      setUploadState((prev) => {
        const newState = { ...prev };
        delete newState[dataId];
        return newState;
      });
    }
  };

  const removeDocument = async (dataId: string, documentId: string) => {
    try {
      // Update the project directly instead of using uploadState
      const currentData = project.requiredCompanyData?.find(
        (d) => d.id === dataId,
      );
      if (!currentData) return;

      const newDocuments = currentData.documents?.filter(
        (doc) => doc.id !== documentId,
      );
      const newRequiredCompanyData = project.requiredCompanyData?.map((data) =>
        data.id === dataId ? { ...data, documents: newDocuments } : data,
      );

      await SeevClient.project.updateProject(project.id, {
        requiredCompanyData: newRequiredCompanyData,
      });

      setProject({
        ...project,
        requiredCompanyData: newRequiredCompanyData,
      });
    } catch (error) {
      console.error("Failed to remove document:", error);
    }
  };

  return (
    <div className="flex flex-col p-4">
      {project.requiredCompanyData?.map((data) => (
        <div key={data.id} className="mb-2">
          <div className="font-semibold mb-2">{data.name}</div>
          <div className="pl-4">
            <FileUploadZone
              onUpload={(files) => handleFileUpload(files, data.id)}
              description={data.description}
            />

            <div className="mt-4">
              {/* Show existing documents */}
              {(data.documents || []).map((doc) => (
                <FileItem
                  key={doc.id}
                  document={doc}
                  onRemove={() => removeDocument(data.id, doc.id)}
                />
              ))}

              {/* Show newly uploaded documents */}
              {uploadState[data.id]?.documents?.map((doc) => (
                <FileItem
                  key={doc.id}
                  document={doc}
                  onRemove={() => removeDocument(data.id, doc.id)}
                />
              ))}
            </div>
          </div>
          <div className="h-px w-full bg-border my-4"></div>
        </div>
      ))}
    </div>
  );
}

function FileUploadZone({
  onUpload,
  description,
}: {
  onUpload: (files: File[]) => void;
  description: string;
}) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onUpload,
  });

  return (
    <div
      {...getRootProps()}
      className={`
        border-2 border-dashed rounded-lg p-4 text-center cursor-pointer
        transition-colors duration-200
        ${isDragActive ? "border-primary bg-primary/10" : "border-border"}
      `}
    >
      <input {...getInputProps()} />
      <Upload className="mx-auto h-6 w-6 text-muted-foreground mb-2" />
      <p className="text-sm text-muted-foreground">{description}</p>
    </div>
  );
}

function FileItem({
  document,
  onRemove,
}: {
  document: RequiredCompanyDataDocument;
  onRemove: () => void;
}) {
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <File className="mr-2 h-4 w-4 text-muted-foreground" />
        <span className="text-sm text-muted-foreground">{document.name}</span>
      </div>
      <Button variant="ghost" size="icon" onClick={onRemove}>
        <X className="h-4 w-4 text-muted-foreground" />
      </Button>
    </div>
  );
}

export default RequiredCompanyDataCard;
